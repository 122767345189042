import React from 'react'
import { MDXProvider, MDXProviderComponents } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { Layout, SEO, Container, SectionSpacer, PageTitle } from '../core'
import { ResponsiveVideo } from '../core/ResponsiveVideo'

// Add any components you wish to expose to MDX sales-content pages
const MDX_COMPONENTS: MDXProviderComponents = {
    ResponsiveVideo,
}

const PageLayout = (props: any) => {
    const { title, body } = props.pageContext.content

    return (
        <Layout
            locale={props.pageContext.localeConfig}
            path={props.path}
            hasContactSection={false}
        >
            <SEO
                title={title}
                extraMetas={[{ name: 'robots', content: 'noindex' }]}
            />
            <PageTitle>{title}</PageTitle>
            <Container>
                <MDXProvider components={MDX_COMPONENTS}>
                    <MDXRenderer>{body}</MDXRenderer>
                </MDXProvider>
            </Container>
            <SectionSpacer />
        </Layout>
    )
}

export default PageLayout
